<template>
  <div>
    <v-container>
      <v-row no-gutters align="start">
        <v-breadcrumbs
          :items="breadcrumbs"
          class="pa-0 pb-2"
          divider=">"
        ></v-breadcrumbs>
        <v-spacer></v-spacer>

        <v-btn small depressed @click="$router.go(-1)"
          ><v-icon left>mdi-arrow-left</v-icon>Back</v-btn
        >
      </v-row>
      <v-row align="center" no-gutters>
        <v-col cols="2" class="pr-12">
          <h1>Application</h1>
        </v-col>
        <v-col cols="4" class="pr-12" align="center">
          Status:

          <v-tooltip bottom v-if="currentUserRole.name == 'VH Superadmin'">
            <template v-slot:activator="{ on }">
              <v-chip
                @click="$refs.statusDialogAdmin.changeStatusPrompt(form)"
                class="ma-2"
                :color="
                  form.status.colour +
                  ' ' +
                  (form.status.colour == 'yellow'
                    ? 'black--text lighten-5'
                    : form.status.colour + '--text lighten-5')
                "
              >
                {{ form.status.status_desc }}
              </v-chip>
            </template>
            <span>Change status</span>
          </v-tooltip>

          <v-chip
            v-else
            class="ma-2"
            :color="
              form.status.colour +
              ' ' +
              (form.status.colour == 'yellow'
                ? 'black--text lighten-5'
                : form.status.colour + '--text lighten-5')
            "
          >
            {{ form.status.status_desc }}
          </v-chip>
        </v-col>
        <v-col cols="6" align="right">
          <v-btn
            small
            depressed
            class="mr-2"
            color="green green--text lighten-4"
            :loading="pdfLoading"
            @click="downloadPdf"
            >Generate PDF</v-btn
          >

          <v-btn
            v-if="form.status.status_desc == 'Submitted'"
            small
            depressed
            class="mr-2"
            @click="
              $refs.statusDialog.changeStatusPrompt(form, 'Under Consideration')
            "
            color="blue blue--text lighten-4"
            >Move to Under Consideration</v-btn
          >

          <v-btn
            v-if="form.status.status_desc == 'Under Consideration'"
            small
            depressed
            class="mr-2"
            @click="
              $refs.statusDialog.changeStatusPrompt(form, 'Invited to Viewing')
            "
            color="blue blue--text lighten-4"
            >Move to Invited to Viewing
          </v-btn>

          <v-btn
            v-if="form.status.status_desc == 'Invited to Viewing'"
            small
            depressed
            class="mr-2"
            @click="
              $refs.statusDialog.changeStatusPrompt(
                form,
                'Referencing in Progress'
              )
            "
            color="blue blue--text lighten-4"
            >Move to Referencing in Progress
          </v-btn>

          <v-btn
            v-if="form.status.status_desc == 'Referencing in Progress'"
            small
            depressed
            class="mr-2"
            @click="
              $refs.statusDialog.changeStatusPrompt(form, 'With Landlord')
            "
            color="blue blue--text lighten-4"
            >Move to With Landlord
          </v-btn>

          <v-btn
            v-if="form.status.status_desc == 'With Landlord'"
            small
            depressed
            class="mr-2"
            @click="$refs.statusDialog.changeStatusPrompt(form, 'Accepted')"
            color="blue blue--text lighten-4"
            >Move to Accepted
          </v-btn>

          <v-btn
            v-if="form.status.status_desc == 'Accepted'"
            small
            depressed
            class="mr-2"
            @click="
              $refs.statusDialog.changeStatusPrompt(
                form,
                'Landlord Advised of Entry Date'
              )
            "
            color="blue blue--text lighten-4"
            >Move to Landlord Advised of Entry Date
          </v-btn>

          <v-btn
            small
            depressed
            class="mr-2"
            color="red red--text lighten-4"
            @click="$refs.cancellDialog.changeStatusPrompt(form, 'Cancelled')"
            >Cancel</v-btn
          >
          <v-btn
            small
            depressed
            @click="$refs.rejectDialog.changeStatusPrompt(form, 'Rejected')"
            color="red red--text lighten-4"
            >Reject</v-btn
          >
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="6">
          <v-card class="mb-6">
            <v-toolbar flat dark dense :color="appColor">
              <v-toolbar-title>Property</v-toolbar-title>
              <v-spacer></v-spacer>
              <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                  <!-- <v-btn
                    small
                    depressed
                    color="blue lighten-4 blue--text"
                    v-on="on"
                    @click="$refs.propertySmallDialog.openForm(property)"
                  >
                    <v-icon small>mdi-pencil</v-icon>
                  </v-btn> -->
                </template>
                <span>Edit</span>
              </v-tooltip>
            </v-toolbar>
            <v-divider></v-divider>

            <v-simple-table class="mb-5 table" divider="true">
              <tbody>
                <tr>
                  <th>Property</th>
                  <td>{{ form.property.property_name }}</td>
                </tr>
                <tr>
                  <th>Desired Move In Date</th>
                  <td>
                    {{
                      form.desired_move_in_date
                        ? form.desired_move_in_date
                        : "N/A"
                    }}
                  </td>
                </tr>
              </tbody>
            </v-simple-table>
          </v-card>

          <v-card class="mb-6">
            <v-toolbar flat dark dense :color="appColor">
              <v-toolbar-title>Basic Info</v-toolbar-title>
              <v-spacer></v-spacer>
              <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                  <v-btn
                    small
                    depressed
                    color="blue lighten-4 blue--text"
                    v-on="on"
                    @click="$refs.basicDialog.openForm(form)"
                  >
                    <v-icon small>mdi-pencil</v-icon>
                  </v-btn>
                </template>
                <span>Edit</span>
              </v-tooltip>
            </v-toolbar>
            <v-divider></v-divider>
            <v-simple-table class="mb-5 table" divider="true">
              <tbody>
                <tr>
                  <th>Title</th>
                  <td>{{ form.applicant_title }}</td>
                  <td></td>
                  <th>Address Line 1</th>
                  <td>{{ form.add1 }}</td>
                </tr>

                <tr>
                  <th>First Name</th>
                  <td>{{ form.applicant_first_name }}</td>
                  <td></td>
                  <th>Address Line 2</th>
                  <td>{{ form.add2 }}</td>
                </tr>

                <tr>
                  <th>Middle Name(s)</th>
                  <td>{{ form.applicant_middle_name }}</td>
                  <td></td>
                  <th>City</th>
                  <td>{{ form.town }}</td>
                </tr>

                <tr>
                  <th>Surname</th>
                  <td>{{ form.applicant_last_name }}</td>
                  <td></td>
                  <th>Postcode</th>
                  <td>{{ form.postcode }}</td>
                </tr>

                <tr>
                  <th>DOB</th>
                  <td>{{ formatDate(form.applicant_dob) }}</td>
                  <td></td>
                  <td></td>
                  <td></td>
                </tr>

                <tr>
                  <th>NI Number</th>
                  <td>{{ form.applicant_NI }}</td>
                  <td></td>
                  <th>Smoker</th>
                  <td>{{ form.smoker == 1 ? "Yes" : "No" }}</td>
                </tr>

                <tr>
                  <th>Mobile</th>
                  <td>{{ form.phone }}</td>
                  <td></td>
                  <th>Has Pets</th>
                  <td>{{ form.pets == 1 ? "Yes" : "No" }}</td>
                </tr>

                <tr>
                  <th>Email</th>
                  <td>{{ form.email }}</td>
                  <td></td>
                  <th>Dependents</th>
                  <td>{{ form.number_of_dependents }}</td>
                </tr>
              </tbody>
            </v-simple-table>
          </v-card>

          <v-card class="mb-6">
            <v-toolbar flat dark dense :color="appColor">
              <v-toolbar-title>Current Landlord</v-toolbar-title>
              <v-spacer></v-spacer>
              <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                  <v-btn
                    small
                    depressed
                    color="blue lighten-4 blue--text"
                    v-on="on"
                    @click="$refs.currentLandlordDialog.openForm(form)"
                  >
                    <v-icon small>mdi-pencil</v-icon>
                  </v-btn>
                </template>
                <span>Edit</span>
              </v-tooltip>
            </v-toolbar>
            <v-divider></v-divider>
            <v-simple-table class="mb-5 table" divider="true">
              <tbody>
                <tr>
                  <th>Name</th>
                  <td>{{ form.landlord_name }}</td>
                </tr>
                <!-- <tr>
                  <td>Email</td>
                  <td>john@smith.com</td>
                </tr> -->
                <tr>
                  <th>Address</th>
                  <td>
                    {{ form.landlord_add_1 }} {{ form.landlord_add_2 }}
                    {{ form.landlord_town }} {{ form.landlord_postcode }}
                  </td>
                </tr>
              </tbody>
            </v-simple-table>
          </v-card>

          <v-card class="mb-6">
            <v-toolbar flat dark dense :color="appColor">
              <v-toolbar-title>Employment</v-toolbar-title>
              <v-spacer></v-spacer>
              <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                  <v-btn
                    small
                    depressed
                    color="blue lighten-4 blue--text"
                    v-on="on"
                    @click="$refs.employmentDialog.openForm(form)"
                  >
                    <v-icon small>mdi-pencil</v-icon>
                  </v-btn>
                </template>
                <span>Edit</span>
              </v-tooltip>
            </v-toolbar>
            <v-divider></v-divider>

            <v-simple-table class="mb-5 table" divider="true">
              <tbody>
                <tr>
                  <th>Status</th>
                  <td>{{ form.employment_status }}</td>
                </tr>
                <tr>
                  <th>Occupation</th>
                  <td>{{ form.occupation }}</td>
                </tr>
                <tr>
                  <th>Employer Details</th>
                  <td>
                    {{ form.employer_name }} {{ form.employer_add_1 }}
                    {{ form.employer_add_2 }} {{ form.employer_town }}

                    {{ form.employer_postcode }}
                  </td>
                </tr>
                <tr>
                  <th>Additional Info</th>
                  <td>{{ form.other_means }}</td>
                </tr>
                <tr>
                  <th>Monthly Income</th>
                  <td>{{ form.monthly_salary }}</td>
                </tr>
              </tbody>
            </v-simple-table>
          </v-card>

          <v-card class="mb-6">
            <v-toolbar flat dark dense :color="appColor">
              <v-toolbar-title>Guarantor</v-toolbar-title>
              <v-spacer></v-spacer>
              <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                  <v-btn
                    small
                    depressed
                    color="blue lighten-4 blue--text"
                    v-on="on"
                    @click="$refs.guarantorDialog.openForm(form)"
                  >
                    <v-icon small>mdi-pencil</v-icon>
                  </v-btn>
                </template>
                <span>Edit</span>
              </v-tooltip>
            </v-toolbar>
            <v-divider></v-divider>

            <v-simple-table class="mb-5 table" divider="true">
              <tbody>
                <tr>
                  <th>Guarantor needed</th>
                  <td>{{ form.student_guarantor ? "Yes" : "No" }}</td>
                </tr>
                <tr>
                  <th>Name</th>
                  <td>{{ form.guarantor_name }}</td>
                </tr>
                <tr>
                  <th>Address</th>
                  <td>{{ form.guarantor_address }}</td>
                </tr>
                <tr>
                  <th>Relationship</th>
                  <td>
                    {{ form.guarantor_relationship }}
                  </td>
                </tr>
              </tbody>
            </v-simple-table>
          </v-card>

          <v-card class="mb-6">
            <v-toolbar flat dark dense :color="appColor">
              <v-toolbar-title
                >Additional Comments from Applicant</v-toolbar-title
              >
              <v-spacer></v-spacer>
              <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                  <v-btn
                    small
                    depressed
                    color="blue lighten-4 blue--text"
                    v-on="on"
                    @click="$refs.additionalCommentsDialog.openForm(form)"
                  >
                    <v-icon small>mdi-pencil</v-icon>
                  </v-btn>
                </template>
                <span>Edit</span>
              </v-tooltip>
            </v-toolbar>
            <v-divider></v-divider>

            <v-card-text>
              {{ form.additional_comments }}
            </v-card-text>
          </v-card>
        </v-col>
        <v-col cols="6">
          <v-card class="mb-6">
            <v-toolbar flat dark dense :color="appColor">
              <v-toolbar-title>Notes</v-toolbar-title>
              <v-spacer></v-spacer>
              <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                  <v-btn
                    small
                    depressed
                    color="green lighten-4 green--text"
                    v-on="on"
                    @click="$refs.noteDialog.openForm(form)"
                  >
                    <v-icon small>mdi-plus</v-icon>
                  </v-btn>
                </template>
                <span>Edit</span>
              </v-tooltip>
            </v-toolbar>
            <v-divider></v-divider>
            <v-data-table
              :headers="noteTableHeaders"
              :items="form.notes"
              no-data-text="No Notes"
            >
              <template v-slot:item.created_at="{ item }">
                {{ formatDateTime(item.created_at, true) }}
              </template>
            </v-data-table>
          </v-card>

          <v-card class="mb-6">
            <v-toolbar flat dark dense :color="appColor">
              <v-toolbar-title>Viewing</v-toolbar-title>
              <v-spacer></v-spacer>
            </v-toolbar>
            <v-divider></v-divider>
            <v-simple-table class="mb-5 table" divider="true">
              <tbody>
                <tr>
                  <th>Attending viewing</th>
                  <td>
                    <v-switch
                      v-model="form.attending_viewing"
                      @click="openSwitch(form)"
                    ></v-switch>
                  </td>
                </tr>
                <tr>
                  <th>Interested in Property</th>
                  <td>
                    <v-switch
                      v-model="form.interested_in_property"
                      @click="openSwitchInterented(form)"
                    ></v-switch>
                  </td>
                </tr>
                <tr>
                  <th>Not attending viewing</th>
                  <td>
                    <v-switch
                      v-model="form.not_attending_viewing"
                      @click="openSwitchNot(form)"
                    ></v-switch>
                  </td>
                </tr>
                <tr>
                  <th>Not attending viewing</th>
                  <td>
                    <v-switch
                      v-model="form.not_attending_viewing"
                      @click="openSwitchNot(form)"
                    ></v-switch>
                  </td>
                </tr>
              </tbody>
            </v-simple-table>
          </v-card>
          <v-card class="mb-6">
            <v-toolbar flat dark dense :color="appColor">
              <v-toolbar-title>Checklist</v-toolbar-title>
              <v-spacer></v-spacer>
            </v-toolbar>
            <v-divider></v-divider>
            <v-simple-table class="mb-5 table" divider="true">
              <tbody>
                <tr>
                  <th>Item</th>
                  <th>Complete</th>
                  <th>Notes</th>
                  <th>Actions</th>
                </tr>

                <tr v-for="item in form.vouch" v-bind:key="item.id">
                  <th>{{ item.checklist_items.name }}</th>
                  <td>
                    <v-icon v-if="item.completed" color="green"
                      >mdi-check-circle
                    </v-icon>
                    <v-icon v-else color="red">mdi-close-circle </v-icon>
                  </td>
                  <td>{{ item.notes }}</td>
                  <td>
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on }">
                        <v-btn
                          small
                          depressed
                          color="blue lighten-4 blue--text"
                          v-on="on"
                          @click="$refs.checkListDialog.openForm(1, item)"
                        >
                          <v-icon small>mdi-pencil</v-icon>
                        </v-btn>
                      </template>
                      <span>Edit</span>
                    </v-tooltip>
                  </td>
                </tr>
              </tbody>
            </v-simple-table>
          </v-card>

          <v-card class="mb-6">
            <v-toolbar flat dark dense :color="appColor">
              <v-toolbar-title>Guarantor Checklist</v-toolbar-title>
              <v-spacer></v-spacer>
            </v-toolbar>
            <v-divider></v-divider>

            <v-simple-table class="mb-5 table" divider="true">
              <tbody>
                <tr>
                  <th>Item</th>
                  <th>Complete</th>
                  <th>Notes</th>
                  <th>Actions</th>
                </tr>

                <tr
                  v-for="item in form.guarantor_checklist"
                  v-bind:key="item.id"
                >
                  <th>{{ item.checklist_items.name }}</th>
                  <td>
                    <v-icon v-if="item.completed" color="green"
                      >mdi-check-circle
                    </v-icon>
                    <v-icon v-else color="red">mdi-close-circle </v-icon>
                  </td>
                  <td>{{ item.notes }}</td>
                  <td>
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on }">
                        <v-btn
                          small
                          depressed
                          color="blue lighten-4 blue--text"
                          v-on="on"
                          @click="$refs.checkListDialog.openForm(2, item)"
                        >
                          <v-icon small>mdi-pencil</v-icon>
                        </v-btn>
                      </template>
                      <span>Edit</span>
                    </v-tooltip>
                  </td>
                </tr>
              </tbody>
            </v-simple-table>
          </v-card>

          <v-card class="mb-6">
            <v-toolbar flat dark dense :color="appColor">
              <v-toolbar-title>Audit</v-toolbar-title>
              <v-spacer></v-spacer>
            </v-toolbar>
            <v-divider></v-divider>

            <v-data-table
              :headers="tableHeaders"
              :items="form.audits"
              no-data-text="No Audit"
              :items-per-page="-1"
            >
              <template v-slot:item.audit_date="{ item }">
                {{ formatDateTime(item.created_at, true) }}
              </template>
            </v-data-table>
          </v-card>

          <v-card class="mb-6">
            <v-toolbar flat dark dense :color="appColor">
              <v-toolbar-title>Form Consent</v-toolbar-title>
              <v-spacer></v-spacer>
              <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                  <v-btn
                    small
                    depressed
                    color="blue lighten-4 blue--text"
                    v-on="on"
                    @click="$refs.consentDialog.openForm(form)"
                  >
                    <v-icon small>mdi-pencil</v-icon>
                  </v-btn>
                </template>
                <span>Edit</span>
              </v-tooltip>
            </v-toolbar>
            <v-divider></v-divider>

            <v-simple-table class="mb-5 table" divider="true">
              <tbody>
                <tr>
                  <th>
                    To include my contact details in the management systems used
                    by Struan Baptie Property Management. The management systems
                    are available to staff only, but limited information may
                    also be made available to contractors who undertake services
                    for us on behalf of clients and tenants.
                  </th>
                  <td>{{ form.consent_contact == 1 ? "Yes" : "No" }}</td>
                </tr>
                <tr>
                  <th>
                    To keep me informed of news, services and activities of
                    Struan Baptie Property Management.
                  </th>
                  <td>{{ form.consent_news == 1 ? "Yes" : "No" }}</td>
                </tr>
                <tr>
                  <th>
                    To contact me with regards to specific issues regarding a
                    property.
                  </th>
                  <td>{{ form.consent_property == 1 ? "Yes" : "No" }}</td>
                </tr>
                <tr>
                  <th>To contact references provided by me.</th>
                  <td>{{ form.consent_references == 1 ? "Yes" : "No" }}</td>
                </tr>
                <tr>
                  <th>
                    To share my personal information with professional companies
                    that undertake credit checks
                  </th>
                  <td>{{ form.consent_credit_checks == 1 ? "Yes" : "No" }}</td>
                </tr>

                <tr>
                  <th colspan="2">
                    I consent to Struan Baptie Property Management contacting me
                    for the above purposes by:
                  </th>
                </tr>
                <tr>
                  <th>Post</th>
                  <td>{{ form.consent_post == 1 ? "Yes" : "No" }}</td>
                </tr>
                <tr>
                  <th>Email</th>
                  <td>{{ form.consent_email == 1 ? "Yes" : "No" }}</td>
                </tr>
                <tr>
                  <th>Phone</th>
                  <td>{{ form.consent_phone == 1 ? "Yes" : "No" }}</td>
                </tr>
                <tr>
                  <th>SMS</th>
                  <td>{{ form.consent_sms == 1 ? "Yes" : "No" }}</td>
                </tr>
                <tr>
                  <th>
                    I certify that my answers are true and complete to the best
                    of my knowledge. I have read, understood and agree to all
                    the above terms &amp; conditions
                  </th>
                  <td>{{ form.terms_agree == 1 ? "Yes" : "No" }}</td>
                </tr>
                <tr>
                  <th>Type Name</th>
                  <td>{{ form.signature }}</td>
                </tr>
                <tr>
                  <th>Date</th>
                  <td>{{ form.signed_date }}</td>
                </tr>
              </tbody>
            </v-simple-table>
          </v-card>
        </v-col>
      </v-row>
    </v-container>

    <RejectDialog ref="rejectDialog" />
    <CurrentLandlordDialog ref="currentLandlordDialog" />
    <EmploymentDialog ref="employmentDialog" />
    <AdditionalCommentsDialog ref="additionalCommentsDialog" />
    <BasicDialog ref="basicDialog" />
    <StatusDialog ref="statusDialog" :application="form" />
    <StatusDialogAdmin ref="statusDialogAdmin" :application="form" />
    <CancellDialog ref="cancellDialog" />
    <NoteDialog ref="noteDialog" />
    <CheckListDialog ref="checkListDialog" :vouch="form.vouch" />
    <ConsentDialog ref="consentDialog" />
    <GuarantorDialog ref="guarantorDialog" />

    <v-dialog v-model="deleteForm.dialog" persistent max-width="400">
      <v-card>
        <v-card-title class="headline">Archive Form</v-card-title>
        <v-card-text>Are you sure you wish to archive form?</v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="error" depressed @click="resetDelete">No, cancel</v-btn>
          <v-btn
            color="success"
            depressed
            :loading="deleteForm.loading"
            @click="saveDelete"
            >Yes</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import RejectDialog from "../properties/components/RejectDialog.vue";
import CurrentLandlordDialog from "./components/CurrentLandlord.vue";
import EmploymentDialog from "./components/Employment.vue";
import AdditionalCommentsDialog from "./components/AdditionalComments.vue";
import BasicDialog from "./components/Basic.vue";
import StatusDialog from "../properties/components/StatusDialog.vue";
import StatusDialogAdmin from "../applications/components/StatusDialog.vue";
import CancellDialog from "../properties/components/CancellDialog.vue";
import NoteDialog from "./components/NoteDialog.vue";
import CheckListDialog from "./components/CheckListDialog.vue";
import ConsentDialog from "./components/ConsentDialog.vue";
import GuarantorDialog from "./components/GuarantorDialog.vue";

export default {
  props: {
    appColor: {
      type: String,
    },
  },
  components: {
    CurrentLandlordDialog,
    GuarantorDialog,
    ConsentDialog,
    NoteDialog,
    RejectDialog,
    StatusDialog,
    StatusDialogAdmin,
    CancellDialog,
    EmploymentDialog,
    AdditionalCommentsDialog,
    BasicDialog,
    CheckListDialog,
  },

  data() {
    return {
      pdfLoading: false,
      deleteForm: {
        dialog: false,
        form: {},
        loading: false,
      },
      fields: {
        attending_viewing: false,
        not_attending_viewing: false,
      },
      activeHover: false,
      myIcon: {
        name: "mdi-unfold-less-vertical",
        color: "default",
      },
      noteTableHeaders: [
        { text: "Date/Time", value: "created_at" },
        { text: "User", value: "user.full_name" },
        { text: "Note", value: "note_text" },
      ],

      tableHeaders: [
        { text: "Date/Time", value: "audit_date" },
        { text: "User", value: "user.full_name" },
        { text: "Activity", value: "audit_text" },
      ],
      breadcrumbs: [
        {
          text: "Properties",
          disabled: false,
          to: { name: "module-sbpm-properties" },
          exact: true,
        },
      ],
    };
  },

  computed: {
    currentUserRole() {
      let appId = this.$route.params.id;
      let roles = this.$store.getters.roles;

      roles = roles.filter(function (app) {
        return app.app_id == appId;
      });

      return roles[0];
    },

    form() {
      return this.$store.getters["sbpm/applicationsStore/get"];
    },

    applications() {
      return this.$store.getters["sbpm/propertiesStore/applications"];
    },

    applications_group() {
      return this.$store.getters["sbpm/applicationsStore/groups"];
    },
  },
  methods: {
    downloadPdf() {
      const appId = this.$route.params.id;

      const applicationId = this.$route.params.applicationId;
      this.pdfLoading = true;

      this.$store
        .dispatch("sbpm/applicationsStore/downloadApplicationPdf", {
          appId,
          applicationId,
        })
        .then((response) => {
          let blob = new Blob([response.data], { type: "application/pdf" });
          var fileURL = URL.createObjectURL(blob);
          window.open(fileURL);

          this.pdfLoading = false;
        })
        .catch(() => {
          this.pdfLoading = false;
        });
    },

    openSwitch(application) {
      this.loading = true;
      this.errors = {};

      this.fields.attending_viewing = application.attending_viewing ? 1 : 0;

      let payload = {
        appId: this.$route.params.id,
        fields: this.fields,
        applicationId: application.id,
      };

      this.$store
        .dispatch("sbpm/applicationsStore/saveAttendingViewing", payload)
        .then(() => {})
        .catch();
    },

    openSwitchNot(application) {
      this.loading = true;
      this.errors = {};

      this.fields.not_attending_viewing = application.not_attending_viewing
        ? 1
        : 0;

      let payload = {
        appId: this.$route.params.id,
        fields: this.fields,
        applicationId: application.id,
      };

      this.$store
        .dispatch("sbpm/applicationsStore/saveNotAttendingViewing", payload)
        .then(() => {})
        .catch();
    },

    openSwitchInterented(application) {
      this.loading = true;
      this.errors = {};

      this.fields.interested_in_property = application.interested_in_property
        ? 1
        : 0;

      let payload = {
        appId: this.$route.params.id,
        fields: this.fields,
        applicationId: application.id,
      };

      this.$store
        .dispatch("sbpm/applicationsStore/saveInterented", payload)
        .then(() => {})
        .catch();
    },

    openDelete(form) {
      this.deleteForm.form = form;
      this.deleteForm.dialog = true;
    },

    resetDelete() {
      this.deleteForm.dialog = false;
      this.deleteForm.form = {};
      this.deleteForm.loading = false;
    },

    saveDelete() {
      this.deleteForm.loading = true;

      this.$store
        .dispatch("sbpm/propertiesStore/deleteForm", {
          appId: this.$route.params.id,
          formId: this.deleteForm.form.application_id,
        })
        .then(() => {
          this.resetDelete();
        })
        .catch(() => {
          this.deleteForm.loading = false;
        });
    },

    formatPrice(value) {
      let val = (value / 1).toFixed(2).replace(",", ".");
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    },
  },
};
</script>
